














































import {
  defineComponent,
  computed,
  unref,
  PropType,
} from "@vue/composition-api"

import {
  useFormValidation,
  FieldValidationObject,
  ValidationPairs,
} from "@/_validation"
import { useI18n } from "@/_i18n"
import { I18n_T } from "@/_i18n/types"

import Loader from "@/component/Loader.vue"
import SuccessAlert from "@/component/SuccessAlert.vue"

export default defineComponent({
  name: "FormCard",

  props: {
    title: {
      type: String,
      required: false,
    },
    subTitle: {
      type: String,
      required: false,
    },
    showError: {
      type: Boolean,
      default: false,
    },
    validationRules: {
      type: Object as PropType<
        Record<string, ValidationPairs | FieldValidationObject> | undefined
      >,
      required: false,
    },
    validationTranslator: {
      type: Function as PropType<I18n_T>,
      required: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    successMessage: {
      type: String,
      default: undefined,
    },
  },

  components: { Loader, SuccessAlert },

  setup(props, ctx) {
    const validationRules = props.validationRules

    const validationTranslator = props.validationTranslator

    const { locale, t } = useI18n()

    let validationData

    if (validationRules) {
      validationData = useFormValidation(
        locale,
        validationTranslator ? validationTranslator : t,
        validationRules
      )
    } else {
      validationData = {
        form: undefined,
        isFormValid: undefined,
        formRules: undefined,
      }
    }

    const { form, isFormValid, formRules } = validationData

    const validate = () => (form ? form.value?.validate() : true)
    const resetValidation = () => (form ? form.value?.resetValidation() : true)
    const reset = () => (form ? form.value?.reset() : true)

    const scopedData = computed(() => ({
      isFormValid: unref(isFormValid),
      form: unref(form),
      formRules: unref(formRules),
      validate,
    }))

    const onSubmit = () => {
      if (validate()) {
        ctx.emit("submit")
        ctx.root.$nextTick(() => resetValidation())
      }
    }

    return {
      isFormValid,
      resetValidation,
      reset,
      form,
      formRules,
      scopedData,
      onSubmit,
    }
  },
})
