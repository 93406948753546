/**
 * value validation function returns {@code true} if given value is valid,
 * {@code false} otherwise
 */
import { ComputedRef, Ref } from "@vue/composition-api"

export type Predicate = (v: unknown) => boolean

/**
 * `required` validator, checks whether the given value is truthy
 *
 * @param v     value to be checked
 */
export function required(v: unknown): boolean {
  if (Array.isArray(v)) return !!v.length

  if (v === undefined || v === null) {
    return false
  }

  if (v === false) {
    return true
  }

  if (v instanceof Date) {
    // invalid date won't pass
    return !isNaN(v.getTime())
  }

  if (typeof v === "object") {
    for (const _ in v) return true
    return false
  }

  return !!String(v).length
}

/**
 * checks whether the given value is falsy (empty)
 *
 * @param v     value to be checked
 */
export function isEmpty(v: unknown): boolean {
  return !required(v)
}

/**
 * conditional `required` validator, creates the `required` validator, which
 * will be used if specified condition is met
 *
 * @param condition   reactive boolean value to be used as a precondition
 *                    for {@code required} validator
 */
export function requiredIf(
  condition: Ref<boolean> | ComputedRef<boolean>
): Predicate {
  return (v: unknown): boolean => {
    return !condition.value || required(v)
  }
}

/**
 * checks whether the specified regular expression is matched for a given value
 *
 * @param expr        regular expression to match
 * @param checkEmpty  if set to {@code true}, then even empty value will be
 *                    checked to match the regexp
 */
export function regex(expr: RegExp, checkEmpty = false): Predicate {
  return (v: unknown): boolean => {
    const isBlank = isEmpty(v)
    return (!checkEmpty && isBlank) || expr.test(isBlank ? "" : (v as string))
  }
}

/**
 * checks whether the specified regular expression is matched for a given value
 * (even for empty string)
 *
 * @param expr        regular expression to match
 */
export function strictRegex(expr: RegExp): Predicate {
  return regex(expr, true)
}

const NO_WHITESPACE = /[^\s-]/

/**
 * validator for checking if the given value does not contain a whitespace
 *
 * @param v       value to be checked
 */
export function noWhitespace(v: unknown): boolean {
  return regex(NO_WHITESPACE)(v)
}

/**
 * regular expression, which is matched by e-mail addresses
 */
const EMAIL_REGEX = /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/

/**
 * e-mail validator, checks whether the specified value is a valid e-mail
 * address
 *
 * @param v       value to be checked
 */
export function email(v: unknown): boolean {
  return regex(EMAIL_REGEX)(v)
}

/**
 * the logical negation of given predicate
 *
 * @param predicate   predicate to be negated
 */
export function not(predicate: Predicate): Predicate {
  return (v: unknown): boolean => !predicate(v)
}

/**
 * the logical concatenation of given predicates
 *
 * @param predicates    predicates to be concatenated
 */
export function and(...predicates: Array<Predicate>): Predicate {
  return (v: unknown): boolean => predicates.every((it) => it(v))
}

/**
 * the logical OR of given predicates
 *
 * @param predicates    predicates to be checked
 */
export function or(...predicates: Array<Predicate>): Predicate {
  return (v: unknown): boolean => predicates.some((it) => it(v))
}

/**
 * checks whether the given value contains different character types,
 * not just digits or uppercase or lowercase letters
 */
export const variousPassword = not(
  or(strictRegex(/^[a-z]+$/), strictRegex(/^[A-Z]+$/), strictRegex(/^[0-9]+$/))
)

/**
 * minimal string length, used for password validation
 */
export const MINIMAL_PASSWORD_LENGTH = 8

/**
 * checks whether the given value has the minimal specified length
 * (empty values are ignored, use `required` to invalidate them)
 *
 * @param len     minimal allowed length
 */
export function minLen(len: number): Predicate {
  return (v: unknown): boolean => {
    return typeof v === "string" || Array.isArray(v)
      ? v.length === 0 || v.length >= len
      : true
  }
}
