import { isRef, ref, Ref, UnwrapRef, watch } from "@vue/composition-api"

import { I18n_T, I18n_T_Params } from "@/_i18n/types"

import { Predicate } from "./"
import VueI18n from "vue-i18n"

/**
 * value validation function, used for Vuetify library field validation rules,
 * returns {@code true} if given value is valid, or error message {@code string}
 * otherwise
 */
export type VPredicate = (v: unknown) => boolean | string

/**
 * An object, which can be validated, e.g. `VForm` from Vuetify library.
 *
 * Actually, the type with `validate` method should be provided by Vuetify
 * library to be able to use references to `VForm` and enforce their validation.
 * TODO: check this after Vuetify 3.0 release
 */
export interface Validatable {
  validate(): boolean
  resetValidation(): void
  reset(): void
}

/**
 *  mapping of validator function (rule in terms of Vuetify) to error message key
 */
export type ValidationPair = [Predicate, VueI18n.Path | I18n_T_Params]

/**
 * array of mappings of validator function (rule in terms of Vuetify) to
 * error message key
 */
export type ValidationPairs = Array<ValidationPair>

/**
 * object to be used for complex (e.g. cross-field) validation definitions
 */
export interface FieldValidationObject {
  ruleDefs: ValidationPairs
  /**
   * field dependencies (the field need to be validated if dependency changed)
   */
  deps: Ref | Array<Ref>
}

/**
 * the function, creating the field rules, used for Vuetify component
 * validation
 *
 * @param t         translation function to convert error message keys into
 *                  error messages according to current application locale
 * @param vPairs    array of mappings of validator function (predicate) to
 *                  error message key
 */
function vFieldRules(t: I18n_T, vPairs: ValidationPairs): Array<VPredicate> {
  return vPairs.map((it) => {
    const [predicate, tParam] = it

    return (v: unknown) =>
      predicate(v) ||
      ((Array.isArray(tParam) ? t(...tParam) : t(tParam)) as string)
  })
}

type FormValidationRules = Record<string, Array<VPredicate>>

/**
 * form validation properties
 */
export interface FormValidation {
  /**
   * form reference (need to be used in template on `VForm` component)
   */
  form: Ref<Validatable | undefined>
  /**
   * the form `value`
   * (need to be assigned as `v-model` in template on `VForm` component)
   */
  isFormValid: Ref<boolean>
  /**
   * rules for form fields
   * (should be used in `rules` attributes of Vuetify input components,
   * e.g. `VInput`)
   */
  formRules: FormValidationRules
}

/**
 * find the input with the given name inside the given form
 *
 * @param form        the `VForm` component, representing the form, which fields
 *                    need to be validated
 * @param inputName   the name of the input field to be looked for
 */
// FIXME: there are no type definitions for `VForm` and `VInput` components
//  see https://github.com/vuetifyjs/vuetify/issues/5962
// eslint-disable-next-line
function findInput(form: any, inputName: string): Validatable {
  // eslint-disable-next-line
  return form.inputs.filter((it: any) => it.$attrs?.name === inputName)[0]
}

/**
 * validate the field with given name inside given form on each dependency change
 *
 * @param form        the form, containing the field to be validated
 * @param fieldName   the name of field to be validated
 * @param dependency  dependency to be watched for changes
 */
function validateOnChange(
  form: Ref<UnwrapRef<Validatable>> | Ref<UnwrapRef<undefined>>,
  fieldName: string,
  dependency: Ref<unknown>
) {
  watch(dependency, () => {
    const input = findInput(form.value, fieldName)
    input.validate()
  })
}

/**
 * create form validation properties
 *
 * @param locale                  application locale
 * @param t                       i18n translation function
 * @param formValidationObject    validation definitions for form fields
 */
export function useFormValidation(
  locale: Ref<string>,
  t: I18n_T,
  formValidationObject: Record<string, ValidationPairs | FieldValidationObject>
): FormValidation {
  // this block represents the workaround needed to enforce form validation
  // after `locale` changes to see translated validation messages
  const form = ref<Validatable | undefined>(undefined)
  watch(locale, () => {
    form.value?.validate()
  })

  const isFormValid = ref(false)

  const formRules: FormValidationRules = {}

  Object.keys(formValidationObject).forEach((fieldName) => {
    const fieldValidations = formValidationObject[fieldName]
    const fieldValidationObject = fieldValidations as FieldValidationObject
    const validationPairs = fieldValidationObject.ruleDefs || fieldValidations
    const deps = fieldValidationObject.deps

    if (deps) {
      if (isRef(deps)) {
        validateOnChange(form, fieldName, deps)
      } else {
        if (Array.isArray(deps)) {
          deps.forEach((it) => validateOnChange(form, fieldName, it))
        }
      }
    }

    formRules[fieldName] = vFieldRules(t, validationPairs)
  })

  return { form, isFormValid, formRules }
}
