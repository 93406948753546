




























import { defineComponent, computed, PropType } from "@vue/composition-api"

import { useI18n } from "@/_i18n"
import { UseCached } from "@/_store/storeHelper"

export default defineComponent({
  name: "CacheSelector",

  props: {
    value: {
      type: [Number, String],
      default: undefined,
    },
    useStore: {
      type: Function as PropType<
        <T extends Record<string, unknown>>() => UseCached<T>
      >,
      required: true,
    },
    itemValue: {
      type: String,
      default: "id",
    },
    itemText: {
      type: String,
      default: "name",
    },
  },
  setup(props, ctx) {
    const attrs = computed(() => ctx.attrs)

    const { t } = useI18n()

    const { cached, isLoading, refresh } = props.useStore()

    const items = computed(() =>
      [
        ...((cached?.value || []) as Array<Record<string, unknown>>),
      ]?.sort((a, b) =>
        (a[props.itemText] as string)?.localeCompare?.(
          b[props.itemText] as string
        )
      )
    )

    const selected = computed({
      get: () => {
        return props.value
      },
      set: (newVal: number | string) => {
        ctx.emit("input", newVal)
      },
    })

    return { attrs, items, isLoading, refresh, selected, t }
  },
})
