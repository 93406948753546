import { watch } from "@vue/composition-api"
import VueI18n from "vue-i18n"
import { setRouteTitle } from "@/_store/routing"

/**
 * set the route title according to return value ogf given function
 *
 * @param getTitle      function to be called to set the route title
 */
export function useRouteTitle(
  getTitle: () => VueI18n.LocaleMessages | string
): void {
  watch(
    getTitle,
    () => {
      setRouteTitle(getTitle() as string)
    },
    { immediate: true }
  )
}
