







































import { computed, defineComponent, PropType, ref } from "@vue/composition-api"

import { useI18n } from "@/_i18n"
import { required, Validatable } from "@/_validation"
import { Client, createClient, updateClient } from "@/_service/client"
import { useApiCall } from "@/_use/apiCall"

import FormCard from "@/component/FormCard.vue"
import BackendSelect from "@/component/MailBackendSelect.vue"
import { useClients } from "@/_use/client"
import { useRouteTitle } from "@/_use/routeTitle"

export default defineComponent({
  name: "ClientCreateUpdate",

  components: { FormCard, BackendSelect },

  props: {
    isCreate: Boolean,
    clientData: {
      type: Object as PropType<Client>,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n(
      require.context("./", true, /ClientCreateUpdate\.[\w-]+\.(json|ya?ml)$/i)
    )

    const mode = computed<string>(() => (props.isCreate ? "create" : "update"))

    useRouteTitle(() => t(`title_${mode.value}`))

    const { refresh } = useClients(true)

    const client = computed(() => ({ ...props.clientData }))

    const form = ref<Validatable | undefined>(undefined)
    const successMsg = ref<string | undefined>(undefined)
    const onSuccess = () => {
      successMsg.value = successMsg.value = t(`success_${mode.value}`, {
        name: client.value?.name,
      }) as string
      refresh()
      props.isCreate && form.value?.reset()
      form.value?.resetValidation()
    }

    const {
      onSubmit,
      hasErrors: isErrorVisible,
      isLoading,
      errorMessage,
    } = props.isCreate
      ? useApiCall(createClient, t, client.value, onSuccess)
      : useApiCall(updateClient, t, client.value, onSuccess)

    const validationRules = {
      clientName: {
        ruleDefs: [[required, "validation.clientName.required"]],
      },
      backendId: {
        ruleDefs: [[required, "validation.backendId.required"]],
      },
    }

    return {
      client,
      errorMessage,
      form,
      isErrorVisible,
      isLoading,
      onSubmit: () => {
        successMsg.value = undefined
        onSubmit()
      },
      successMsg,
      t,
      validationRules,
    }
  },
})
